import Vue from "vue";
import axios from "axios";

import { Notification, MessageBox, Message } from "element-ui";

// 第三方插件
import "element-ui/lib/theme-chalk/index.css";

Vue.prototype.$axios = axios;
// 字体图标

const service = axios.create({
	baseURL: (Vue.prototype.BASE_URL),
	timeout: 50000,
	contentType: "application/x-www-form-urlencoded"
});

service.defaults.withCredentials = true; // 让ajax携带cookie
service.interceptors.request.use(
	// 每次请求都自动携带Cookie
	config => {
		let  user = JSON.parse(localStorage.getItem("user"));
		if ((user!=null && user.token != null) && (config.url!='sys/auth/getLoginToken')) {
			config.headers['token'] = user.token;
		}
		return config;
	},
	error => {
		console.log(error)
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	rsp => {

		return rsp;
	},
	// 拦截异常的响应
	err => {
		console.log("S")
		console.log(err)
		console.log("S")
		switch (err.response.status) {
			case 400:
				Notification.error({ title: "提醒", message: err.response.data});
				break;
			case 401:
				Notification.error({ title: "提醒", message: "登陆已过期" });
				break;
			case 403:
				Message.warning("抱歉，您无权访问！")
				break;
			case 500:
				Notification.error({ title: "提醒", message: "服务器出了点小错误" });
				break;
			case 404:
				Notification({
					title: "提醒",
					message: "未找到，检查参数",
					type: "warning"
				});
				break;
			default:
				//throw 'request error'
				return Promise.reject(err);
		}
		//throw 'request error'
		return Promise.reject(err);
	}
);

export default service;
